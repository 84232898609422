import alertify from 'alertifyjs';


export const config = (d) => {

    let domain = window.location.hostname;
    let base_url = 'https://broker.mybrightoffice.co.uk/Webservices/BrightOfficeAPI.asmx/'
    let logo = '/images/logo-large.png';
    let visible_articles = true; // globaly enabled but it can be disabled for specific users/clients
    let visible_quick_quote_btn = true;
    let assessor_questions = [44,45,46,47,48,49,50,51,52,53,54,55,56,57];
    let cleanUpNames = [''];
    let nav_links = [
        ['/form/29', 'Add Lead'],
    ]

    let forms = [
            // Cases
            // { "name": "Heads of Terms Input", "type": "link_case", "resource": "/form/8"  },
            { "name": "Heads of Terms Input", "type": "link_case", "resource": "/form/14", "b2b": true  },
            { "name": "Heads of Terms", "type": "pdf_case", "resource": "Heads of Terms", "b2b": true   },
            { "name": "Heads of Terms Input", "type": "link_case", "resource": "/form/18", "b2b": false },
            { "name": "Heads of Terms", "type": "pdf_case", "resource": "Heads of Terms Ind", "b2b": false  },
            { "name": "Proposal Form", "type": "docx_case", "resource": "/clientworddocs/rewardfinance/Proposal Form.pdf" },
            { "name": "Proposal Form Input", "type": "link_case", "resource": "/form/37", "b2b": true   },
            { "name": "Proposal Form Input", "type": "link_case", "resource": "/form/38", "b2b": false, 'group': "AAA"  },
            { "name": "Asset and Liability Statement", "type": "docx_case", "resource": "/clientworddocs/rewardfinance/Statement of Personal Assets and Liabilities.pdf"  }, 
            { "name": "Etridge Letter", "type": "docx_case", "resource": "/clientworddocs/rewardfinance/Etridge Letter to 3P Obligor.docx"  },
            { "name": "Consent Letter", "type": "docx_case", "resource": "/clientworddocs/rewardfinance/Letter of Consent (property).doc"  },
            { "name": "Risk Matrix Input", "type": "link_case", "resource": "/form/9", "b2b": true  },
            { "name": "Risk Matrix Input", "type": "link_case", "resource": "/form/20", "b2b": false  },
            { "name": "Risk Matrix", "type": "pdf_case", "resource": "Risk Matrix Portal"  },
            { "name": "Credit Paper Input", "type": "link_case", "resource": "/form/10", "b2b": true  },
            { "name": "Credit Paper Input", "type": "link_case", "resource": "/form/21", "b2b": false  },
            { "name": "Credit Paper", "type": "pdf_case", "resource": "Credit Paper", "ReturnDocType": "DOC", "b2b": false  },
            { "name": "Credit Paper", "type": "pdf_case", "resource": "Credit Paper -Business", "ReturnDocType": "DOC", "b2b": true  },
            { "name": "Drawdown Request Form", "type": "pdf_case", "resource": "Drawdown Request Form", "ReturnDocType": "DOC"  },
            { "name": "Miscellaneous Input", "type": "link_case", "resource": "/form/33", "case_status": 'prospect', "b2b": true   },
            { "name": "Miscellaneous Input", "type": "link_case", "resource": "/form/33", "case_status": 'prospect', "b2b": false  },
            { "name": "Deal Dead", "type": "link_case", "resource": "/form/15", "b2b": false  },
            { "name": "Deal Dead", "type": "link_case", "resource": "/form/23", "b2b": true  },
            { "name": "Deal Complete", "type": "link_case", "resource": "/form/13", "b2b": false    },
            { "name": "Deal Complete", "type": "link_case", "resource": "/form/22", "b2b": true  },
            // { "name": "Review Paper", "type": "pdf_case", "resource": "Review Paper", "ReturnDocType": "DOC", "case_status": 'prospect' },
            // { "name": "Drawdown Request Form", "type": "docx_case", "resource": "/clientworddocs/rewardfinance/Capital - Drawdown Request Form.doc"  },
            //Live Cases
            { "name": "Risk Matrix Input", "type": "link_livecase", "resource": "/form/9", "b2b": true  },
            { "name": "Risk Matrix Input", "type": "link_livecase", "resource": "/form/20", "b2b": false  },
            { "name": "Drawdown Request Form", "type": "pdf_livecase", "resource": "Drawdown Request Form", "ReturnDocType": "DOC"  },
            { "name": "Region Input", "type": "link_livecase", "resource": "/form/33", "b2b": true   },
            { "name": "Region Input", "type": "link_livecase", "resource": "/form/33", "b2b": false  },
            // { "name": "Review Paper", "type": "pdf_livecase", "resource": "Review Paper", "ReturnDocType": "DOC" },

            { "name": "Review Paper", "type": "pdf_case", "resource": "Review Paper", "ReturnDocType": "DOC", "case_status": 'live', "b2b": false },
            { "name": "Review Paper", "type": "pdf_case", "resource": "Review Paper - Business", "ReturnDocType": "DOC", "case_status": 'live', "b2b": true },
            // Prospects
            { "name": "Introducer Input", "type": "link_prospect", "resource": "/form/32"  },
            { "name": "Lead Input", "type": "link_prospect", "resource": "/form/36"  },
            { "name": "Convert to Prospect", "type": "link_prospect", "resource": "/form/30"  },
            { "name": "Not Proceeding", "type": "link_prospect", "resource": "/form/31"  },
    ];

 
   if(domain==='rewardportaltest.mybrightoffice.co.uk') {
        base_url = 'https://broker.mybrightoffice.co.uk/Webservices/BrightOfficeAPI.asmx/';
        logo = '/images/logo-reward-finance.svg';
        visible_articles = false;
        visible_quick_quote_btn = false;
        cleanUpNames = ['calvin.dexter', 'damien.slingsby', 'dominic.sievewright', 'ian.lewis', 'james.mortimore', 'neil.mcgivern', 'nick.battersby', 'noel.haverly', 'peter.cromarty', 'rob.mercer'];
        assessor_questions = [44,45,46,47,48,49,50,51,52,53,54,55,56,57];
        forms = [
            // Cases
            // { "name": "Heads of Terms Input", "type": "link_case", "resource": "/form/8"   },
            { "name": "Heads of Terms Input", "type": "link_case", "resource": "/form/14", "case_status": 'prospect', "b2b": true  },
            { "name": "Heads of Terms", "type": "pdf_case", "resource": "Heads of Terms", "case_status": 'prospect', "b2b": true   },
            { "name": "Heads of Terms Input", "type": "link_case", "resource": "/form/18", "case_status": 'prospect', "b2b": false },
            { "name": "Heads of Terms", "type": "pdf_case", "resource": "Heads of Terms Ind", "case_status": 'prospect', "b2b": false  },
            { "name": "Proposal Form", "type": "docx_case", "resource": "/clientworddocs/rewardfinance/Proposal Form.pdf", "case_status": 'prospect'  },
            { "name": "Proposal Form Input", "type": "link_case", "resource": "/form/37", "case_status": 'prospect', "b2b": true   },
            { "name": "Proposal Form Input", "type": "link_case", "resource": "/form/38", "case_status": 'prospect', "b2b": false  },
            { "name": "Asset and Liability Statement", "type": "docx_case", "resource": "/clientworddocs/rewardfinance/Statement of Personal Assets and Liabilities.pdf", "case_status": 'prospect'  }, 
            { "name": "Etridge Letter", "type": "docx_case", "resource": "/clientworddocs/rewardfinance/Etridge Letter to 3P Obligor.docx", "case_status": 'prospect'  },
            { "name": "Consent Letter", "type": "docx_case", "resource": "/clientworddocs/rewardfinance/Letter of Consent (property).doc", "case_status": 'prospect'  },
            { "name": "Risk Matrix Input", "type": "link_case", "resource": "/form/9", "case_status": 'prospect', "b2b": true  },
            { "name": "Risk Matrix Input", "type": "link_case", "resource": "/form/20", "case_status": 'prospect', "b2b": false  },
            { "name": "Risk Matrix", "type": "pdf_case", "resource": "Risk Matrix Portal", "case_status": 'prospect'  },
            { "name": "Credit Paper Input", "type": "link_case", "resource": "/form/10", "case_status": 'prospect', "b2b": true  },
            { "name": "Credit Paper Input", "type": "link_case", "resource": "/form/21", "case_status": 'prospect', "b2b": false  },
            { "name": "Credit Paper", "type": "pdf_case", "resource": "Credit Paper", "ReturnDocType": "DOC", "case_status": 'prospect', "b2b": false  },
            { "name": "Credit Paper", "type": "pdf_case", "resource": "Credit Paper -Business", "ReturnDocType": "DOC", "case_status": 'prospect', "b2b": true  },
            { "name": "Drawdown Request Form", "type": "pdf_case", "resource": "Drawdown Request Form", "ReturnDocType": "DOC", "case_status": 'prospect'  },
            { "name": "Deal Dead", "type": "link_case", "resource": "/form/15", "case_status": 'prospect', "b2b": false  },
            { "name": "Deal Dead", "type": "link_case", "resource": "/form/23", "case_status": 'prospect', "b2b": true  },
            { "name": "Data Review", "type": "link_case", "resource": "/form/39", "case_status": 'prospect', "b2b": false    },
            { "name": "Data Review", "type": "link_case", "resource": "/form/40", "case_status": 'prospect', "b2b": true  },
            { "name": "Deal Complete", "type": "link_case", "resource": "/form/13", "case_status": 'prospect', "b2b": false    },
            { "name": "Deal Complete", "type": "link_case", "resource": "/form/22", "case_status": 'prospect', "b2b": true  },
            // { "name": "Review Paper", "type": "pdf_case", "resource": "Review Paper", "ReturnDocType": "DOC", "case_status": 'prospect' },
            // { "name": "Miscellaneous Input", "type": "link_case", "resource": "/form/33", "case_status": 'prospect', "b2b": true   },
            // { "name": "Miscellaneous Input", "type": "link_case", "resource": "/form/33", "case_status": 'prospect', "b2b": false  },
            // { "name": "Drawdown Request Form", "type": "docx_case", "resource": "/clientworddocs/rewardfinance/Capital - Drawdown Request Form.doc"  },
            //Live Cases
            { "name": "Data Review", "type": "link_case", "resource": "/form/50", "case_status": 'live', "b2b": true  },
            { "name": "Data Review", "type": "link_case", "resource": "/form/51", "case_status": 'live', "b2b": false  },
            { "name": "Risk Matrix Input", "type": "link_case", "resource": "/form/27", "case_status": 'live', "b2b": true  },
            { "name": "Risk Matrix Input", "type": "link_case", "resource": "/form/28", "case_status": 'live', "b2b": false  },
            { "name": "Risk Matrix", "type": "pdf_case", "resource": "Risk Matrix Portal", "case_status": 'live'  },
            { "name": "Drawdown Request Form", "type": "pdf_case", "resource": "Drawdown Request Form", "ReturnDocType": "DOC", "case_status": 'live'  },
            { "name": "Review Paper", "type": "pdf_case", "resource": "Review Paper", "ReturnDocType": "DOC", "case_status": 'live', "b2b": false },
            { "name": "Review Paper", "type": "pdf_case", "resource": "Review Paper - Business", "ReturnDocType": "DOC", "case_status": 'live', "b2b": true },
            //Review Variation Section in Live cases
            // { "name": "Variation Input", "type": "link_case", "resource": "/form/42", "case_status": 'live', "b2b": true, 'group': "Variation" },
            // { "name": "Variation Input", "type": "link_case", "resource": "/form/42", "case_status": 'live', "b2b": false, 'group': "Variation" },
            // { "name": "Risk Matrix Input", "type": "link_case", "resource": "/form/44", "case_status": 'live', "b2b": true, 'group': "Variation"  },
            // { "name": "Risk Matrix Input", "type": "link_case", "resource": "/form/45", "case_status": 'live', "b2b": false, 'group': "Variation"  },
            // { "name": "Risk Matrix", "type": "pdf_case", "resource": "Risk Matrix Review", "case_status": 'live', "b2b": false, 'group': "Variation" },
            // { "name": "Risk Matrix", "type": "pdf_case", "resource": "Risk Matrix Review", "case_status": 'live', "b2b": true, 'group': "Variation" },
            // { "name": "Variation Paper", "type": "pdf_case", "resource": "Review Paper - Business New", "ReturnDocType": "DOC", "case_status": 'live', "b2b": false, 'group': "Variation"  },
            // { "name": "Variation Paper", "type": "pdf_case", "resource": "Review Paper - Business New", "ReturnDocType": "DOC", "case_status": 'live', "b2b": true, 'group': "Variation"  },
            // { "name": "Variation Submission", "type": "link_case", "resource": "/form/43", "case_status": 'live', "b2b": true, 'group': "Variation"  },
            // { "name": "Variation Submission", "type": "link_case", "resource": "/form/43", "case_status": 'live', "b2b": false, 'group': "Variation"  },
            //New Self area
            //Recently Hidden
            { "name": "Self Certification Input", "type": "link_case", "resource": "/form/48", "case_status": 'live', "b2b": true, 'group': "Self Certification"  },
            { "name": "Self Certification Input", "type": "link_case", "resource": "/form/48", "case_status": 'live', "b2b": false, 'group': "Self Certification"   },
            { "name": "Risk Matrix Input", "type": "link_case", "resource": "/form/44", "case_status": 'live', "b2b": true, 'group': "Self Certification"   },
            { "name": "Risk Matrix Input", "type": "link_case", "resource": "/form/45", "case_status": 'live', "b2b": false, 'group': "Self Certification"   },
            { "name": "Risk Matrix", "type": "pdf_case", "resource": "Risk Matrix Review", "case_status": 'live', "b2b": false, 'group': "Self Certification"   },
            { "name": "Risk Matrix", "type": "pdf_case", "resource": "Risk Matrix Review", "case_status": 'live', "b2b": true, 'group': "Self Certification"   },
            { "name": "Self Certification Paper", "type": "pdf_case", "resource": "Self Certification Review", "ReturnDocType": "DOC", "case_status": 'live', "b2b": false, 'group': "Self Certification" },
            { "name": "Self Certification Paper", "type": "pdf_case", "resource": "Self Certification Review B2B", "ReturnDocType": "DOC", "case_status": 'live', "b2b": true, 'group': "Self Certification" },
            { "name": "Self Certification Submission", "type": "link_case", "resource": "/form/49", "case_status": 'live', "b2b": true, 'group': "Self Certification"   },
            { "name": "Self Certification Submission", "type": "link_case", "resource": "/form/49", "case_status": 'live', "b2b": false, 'group': "Self Certification"   },
            //KeepHidden
            // { "name": "Risk Matrix Variation Sub", "type": "link_case", "resource": "/form/47", "case_status": 'live', "b2b": true  },
            // { "name": "Risk Matrix Variation Sub", "type": "link_case", "resource": "/form/46", "case_status": 'live', "b2b": false  },


            //Dead
            { "name": "Revert to Prospect", "type": "link_dead", "resource": "/form/41", "case_status": 'dead', "b2b": true  },
            { "name": "Revert to Prospect", "type": "link_dead", "resource": "/form/41", "case_status": 'dead', "b2b": false  },

            //Recovery
            { "name": "Data Review", "type": "link_recovery", "resource": "/form/40", "case_status": 'recovery', "b2b": true  },
            { "name": "Data Review", "type": "link_recovery", "resource": "/form/39", "case_status": 'recovery', "b2b": false  },
            { "name": "Risk Matrix Input", "type": "link_recovery", "resource": "/form/27", "case_status": 'recovery', "b2b": true  },
            { "name": "Risk Matrix Input", "type": "link_recovery", "resource": "/form/28", "case_status": 'recovery', "b2b": false  },
            { "name": "Risk Matrix", "type": "pdf_recovery", "resource": "Risk Matrix Portal", "case_status": 'recovery'  },
            { "name": "Drawdown Request Form", "type": "pdf_recovery", "resource": "Drawdown Request Form", "ReturnDocType": "DOC", "case_status": 'recovery'  },
            { "name": "Review Paper", "type": "pdf_recovery", "resource": "Review Paper", "ReturnDocType": "DOC", "case_status": 'recovery', "b2b": false },
            { "name": "Review Paper", "type": "pdf_recovery", "resource": "Review Paper - Business", "ReturnDocType": "DOC", "case_status": 'recovery', "b2b": true },

            //Workout
            { "name": "Data Review", "type": "link_workout", "resource": "/form/40", "case_status": 'workout', "b2b": true  },
            { "name": "Data Review", "type": "link_workout", "resource": "/form/39", "case_status": 'workout', "b2b": false  },
            { "name": "Risk Matrix Input", "type": "link_workout", "resource": "/form/27", "case_status": 'workout', "b2b": true  },
            { "name": "Risk Matrix Input", "type": "link_workout", "resource": "/form/28", "case_status": 'workout', "b2b": false  },
            { "name": "Risk Matrix", "type": "pdf_workout", "resource": "Risk Matrix Portal", "case_status": 'workout'  },
            { "name": "Drawdown Request Form", "type": "pdf_workout", "resource": "Drawdown Request Form", "ReturnDocType": "DOC", "case_status": 'workout'  },
            { "name": "Review Paper", "type": "pdf_workout", "resource": "Review Paper", "ReturnDocType": "DOC", "case_status": 'workout', "b2b": false },
            { "name": "Review Paper", "type": "pdf_workout", "resource": "Review Paper - Business", "ReturnDocType": "DOC", "case_status": 'workout', "b2b": true },

            //Repaid
            //Should Stay Blank

            // Prospects
            { "name": "Introducer Input", "type": "link_prospect", "resource": "/form/32"  },
            { "name": "Lead Input", "type": "link_prospect", "resource": "/form/36"  },
            { "name": "Convert to Prospect", "type": "link_prospect", "resource": "/form/30"  },
            { "name": "Not Proceeding", "type": "link_prospect", "resource": "/form/31"  },
        ];
    }

    if(domain==='localhost') {
        cleanUpNames = [];
    }

    // if local storage is empty we are probably on the login page
    if(localStorage.getItem('bo_email')===null) {
        let data = {
            'nav_links': [],
            'base_url': base_url,
            'logo': logo
        }
        return data;
    }


    return {
        'base_url': base_url,
        'forms': forms,
        'visible_articles': visible_articles,
        'visible_quick_quote_btn': visible_quick_quote_btn,
        'logo': logo,
        'navbarClass': 'white', // dark or white
        'navIconClass': 'dark', // dark or white
        'personName': [localStorage.getItem("bo_user_first_name") ?? '', localStorage.getItem("bo_user_surname") ?? ''].join(' '),
        'custom_css': './css/domain/portal.myrewardfinance.co.uk/default.css',
        'personFunction': 'Portal User',
        'personProfileImage': '/images/profile.png',
        'customerLimitCode': 'C000696', // unused?
        'client_id': 1,
        'cleanUpNames': cleanUpNames,
        'nav_links': nav_links,
        'assessor_questions': assessor_questions
    }

    alertify.confirm('Error', "No config for this user", null, null);
}

